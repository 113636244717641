$mobileB: 700px;
$tablet: 768px;

div {
  display: flex;
}
html,
body {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.App {
  width: 100%;
  .camFooter1 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.3vw;
    p{
      color: #000b77;
    }
a{
  color: red;
}
  }.camFooter2 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.3vw;
    p{
      color: #000b77;
    }
a{
  color: #e3b009;
}
  }
  .navbar {
    z-index: 2;
    background-color: transparent;
    position: absolute;
    top: 2vh;
    width: 100vw;
    nav {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      width: 100%;
      align-items: center;
      a {
        width: 15%;
        @media screen and (max-width: $mobileB) {
          width: 30%;
        }
        .altinyapim {
          width: 150%;
          height: 70%;
        }
      }
      .navbar-items {
        @media screen and (max-width: $tablet) {
          display: none;
        }
        width: 50%;
        text-decoration: none;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        font-weight: 200;
        letter-spacing: 0.1vw;
        a {
          text-decoration: none;
          width: auto;
          color: #000b77;
        }
        select {
          font-weight: 200;
          outline: 0;
          border-style: none;
          background-color: transparent;
          font-size: 16px;
          color: #000b77;
          option {
            font-weight: 200;
          }
        }
      }

      .nav-bar-dropdown-mobile {
        @media screen and (max-width: $tablet) {
          display: flex;
        }
        @media screen and (max-width: $mobileB) {
          margin-left: 10%;
        }
        display: none;
        font-weight: 200;
        outline: 0;
        border-style: none;
        background-color: transparent;
        font-size: 16px;
        option {
          font-weight: 200;
        }
      }
      .navbar-left-items {
        @media screen and (max-width: $tablet) {
          align-items: center;
          justify-content: center;
          width: 15%;
        }
        width: 5%;
        .navbar-menu-button {
          display: none;
          width: 20%;
          @media screen and (max-width: $tablet) {
            display: flex;
            img {
              width: 100%;
              height: 50%;
            }
          }
          @media screen and (max-width: $mobileB) {
            display: flex;
            width: 40%;
            img {
              width: 100%;
              height: 50%;
            }
          }
        }
        .cam {
          @media screen and (max-width: $tablet) {
            width: 25%;
            margin-left: 30%;
          }
          @media screen and (max-width: $mobileB) {
            width: 50%;
            margin-left: 30%;
          }
          width: 50%;
          img {
            width: 100%;
          }
        }
      }
      .navbar-mobile {
        display: none;
        @media screen and (max-width: $tablet) {
          display: block;
          position: absolute;
          top: 50px;
          left: 0px;
          .nav-bar-mobile-items-close {
            display: none;
          }
          .nav-bar-mobile-items {
            width: 100vw;
            height: 100vh;
            flex-direction: column;
            justify-content: start;
            background-color: #000b77;
            a {
              width: 100%;
              text-decoration: none;
              text-align: center;
              color: #fff;
              font-size: 20px;
              text-transform: capitalize;
              font-weight: 200;
              margin-top: 10%;
            }
          }
        }
      }
    }
  }

  .TopFeatureFl{
    text-transform: uppercase;
    font-size: 2vw !important;
    font-weight: 900 !important;
  }

  .home {
    .home-con {
      flex-direction: column;
      .home-entrance {
        a {
          text-decoration: none;
        }
        @media screen and (max-width: $mobileB) {
          background-position: center;
        }
        flex-direction: column;
        background-image: url("./assets/home/img/sliderfotovizyon.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        width: 100vw;
        height: 100vh;
        align-items: center;
        justify-content: center;
        h1 {
          @media screen and (max-width: $mobileB) {
            font-size: 4vw;
          }
          font-size: 2vw;
          text-align: center;
          margin-bottom: 5vh;
          font-weight: 200;
          letter-spacing: 0.7vw;
          color: #000b77;
        }
        img {
          @media screen and (max-width: $mobileB) {
            width: 6vw;
          }
          width: 3vw;
          margin-bottom: 10vh;
        }
      }
      .home-corp {
        @media screen and (max-width: $mobileB) {
          height: 160vh;
        }
        width: 100vw;
        height: 120vh;
        flex-direction: column;
        align-items: center;
        .home-line-plane {
          width: 100vw;
          height: 5vh;
          align-items: center;
          margin-top: 3vh;
          margin-bottom: 3vh;
          hr {
            @media screen and (max-width: $mobileB) {
              height: 1px;
            }
            width: 96vw;
            height: 2px;
            border: none;
            background-color: #000b77;
            margin: 0;
          }
          img {
            height: 4vh;
          }
        }
        h1 {
          @media screen and (max-width: $mobileB) {
            font-size: 9vw;
            letter-spacing: 4vw;
          }
          text-transform: uppercase;
          font-size: 2vw;
          text-align: center;
          color: #000b77;
          margin-left: 2.5vw;
          letter-spacing: 2.5vw;
          font-weight: 200;
        }
        .corp-outer-con {
          @media screen and (max-width: $mobileB) {
            border: none;
          }
          margin-top: 10vh;
          width: 70vw;
          height: 80vh;
          border: #000b77 2px solid;
          align-items: center;
          justify-content: center;
          .corp-exp-con {
            @media screen and (max-width: $mobileB) {
              width: 90vw;
              height: 100vh;
              margin-top: 20vh;
            }
            width: 50vw;
            height: 50vh;
            border: #000b77 2px solid;
            .corp-card {
              @media screen and (max-width: $mobileB) {
              }
              width: 30vw;
              flex-direction: column;
              align-items: center;
              a {
                text-decoration: none;
                color: #000;
              }
              .corp-card-exp {
                @media screen and (max-width: $mobileB) {
                  padding: 1vh 5vw;
                  width: 30vw;
                  height: 30vh;
                  overflow: hidden;
                }
                padding: 3vw;
                padding-top: 0;
                flex-direction: column;
                border: #000 1px solid;
                background-color: #fff;
                h1 {
                  @media screen and (max-width: $mobileB) {
                    font-size: 3vw;
                  }
                  font-size: 1vw;
                  letter-spacing: 0.2vw;
                  color: #000;
                  font-weight: 400;
                  margin: 2vh;
                }
                p {
                  @media screen and (max-width: $mobileB) {
                    font-size: 3vw;
                    letter-spacing: 0.3vw;
                    margin-top: 3vh;
                  }
                  color: #000;
                  font-size: 1vw;
                  font-weight: 200;
                  letter-spacing: 0.1vw;
                }
              }
              .corp-card-button {
                @media screen and (max-width: $mobileB) {
                  width: 25vw;
                  margin-top: 4vh;
                  padding: 2vw;
                }
                margin-top: 1vh;
                border: #000b77 1px solid;
                background-color: #fff;
                width: 15vw;
                padding: 1vw;
                align-items: center;
                justify-content: center;
                p {
                  @media screen and (max-width: $mobileB) {
                    font-size: 3vw;
                  }
                  text-transform: uppercase;
                  text-align: center;
                  letter-spacing: 0.2vw;
                  font-size: 0.8vw;
                }
              }
            }
            .top {
              @media screen and (max-width: $mobileB) {
                top: 120vh;
              }
              position: absolute;
              top: 130vh;
              left: 35vw;
            }
            .left {
              @media screen and (max-width: $mobileB) {
                top: 165vh;
                left: 10vw;
              }
              position: absolute;
              top: 155vh;
              left: 5vw;
            }
            .right {
              @media screen and (max-width: $mobileB) {
                top: 165vh;
                left: 60vw;
              }
              position: absolute;
              top: 155vh;
              left: 65vw;
            }
            .bottom {
              @media screen and (max-width: $mobileB) {
                top: 210vh;
              }
              position: absolute;
              top: 180vh;
              left: 35vw;
            }
          }
        }
      }
      .home-service {
        @media screen and (max-width: $mobileB) {
          height: 260vh;
        }
        width: 100vw;
        height: 150vh;
        flex-direction: column;
        .home-line-cloud {
          width: 100vw;
          height: 5vh;
          align-items: center;
          margin-top: 3vh;
          margin-bottom: 3vh;
          hr {
            @media screen and (max-width: $mobileB) {
              height: 1px;
            }
            width: 100vw;
            height: 2px;
            border: none;
            background-color: #000b77;
            margin: 0;
          }
          img {
            @media screen and (max-width: $mobileB) {
              width: 50vw;
              left: 25vw;
            }
            position: absolute;
            width: 20vw;
            left: 40vw;
          }
        }
        .home-service-con {
          @media screen and (max-width: $mobileB) {
            margin-top: 5vh;
          }
          width: 100vw;
          align-items: center;
          margin-top: 2vh;
          flex-direction: column;
          h1 {
            @media screen and (max-width: $mobileB) {
              font-size: 6vw;
            }
            font-size: 2.5vw;
            text-transform: uppercase;
            font-weight: 200;
            letter-spacing: 2vw;
            color: #000b77;
          }
          .home-service-top {
            @media screen and (max-width: $mobileB) {
              flex-direction: column;
              margin-top: 6vh;
              row-gap: 6vh;
            }
            margin-top: 10vh;
            column-gap: 5vw;
            .home-service-card {
              @media screen and (max-width: $mobileB) {
                width: 80vw;
                height: 40vh;
              }
              flex-direction: column;
              border: #000b77 1px solid;
              width: 20vw;
              height: 50vh;
              align-items: center;
              .home-service-card-img {
                @media screen and (max-width: $mobileB) {
                  margin-top: 5vh;
                }
                margin-top: 10vh;
                height: 10vh;
                align-items: center;
                img {
                  @media screen and (max-width: $mobileB) {
                    width: 48vw;
                  }
                  width: 16vw;
                  height: fit-content;
                }
              }
              h1 {
                @media screen and (max-width: $mobileB) {
                  font-size: 6vw;
                  margin-top: 6vh;
                  width: 70vw;
                  height: 10vh;
                }
                text-align: center;
                width: 20vw;
                height: 10vh;
                letter-spacing: 0.4vw;
                font-size: 1.2vw;
                margin-top: 13vh;
                color: #000b77;
              }
              .home-service-card-button {
                @media screen and (max-width: $mobileB) {
                  padding: 1vh 10vw;
                }
                border: #000 1px solid;
                padding: 1vh 3vw;
                p {
                  @media screen and (max-width: $mobileB) {
                    font-size: 5vw;
                    font-weight: 200;
                  }
                  font-size: 1.2vw;
                  color: #000;
                }
              }
            }
          }
        }
      }
      .home-filo {
        @media screen and (max-width: $mobileB) {
          height: 410vh;
        }
        width: 100vw;
        flex-direction: column;
        margin-bottom: 5%;
        .home-line-plane {
          width: 100vw;
          height: 5vh;
          align-items: center;
          margin-top: 3vh;
          margin-bottom: 3vh;
          hr {
            @media screen and (max-width: $mobileB) {
              height: 1px;
            }
            width: 96vw;
            height: 2px;
            border: none;
            background-color: #000b77;
            margin: 0;
          }
          img {
            height: 4vh;
          }
          .cloud {
            @media screen and (max-width: $mobileB) {
              width: 50vw;
              left: 25vw;
            }
            position: absolute;
            width: 20vw;
            height: auto;
            left: 40vw;
          }
        }
        .home-filo-con {
          flex-direction: column;
          h1 {
            @media screen and (max-width: $mobileB) {
              font-size: 8vw;
            }
            margin-top: 5vh;
            margin-left: 2vw;
            text-align: center;
            font-size: 4vw;
            letter-spacing: 2vw;
            font-weight: 200;
            color: #000b77;
            text-transform: uppercase;
          }
          .home-filo-main {
            @media screen and (max-width: $mobileB) {
              flex-direction: column;
            }
            flex-direction: row;
            .home-filo-left {
              @media screen and (max-width: $mobileB) {
                width: 100vw;
              }
              width: 50vw;
              flex-direction: column;
              align-items: center;
              margin-top: 10vh;
              h5 {
                @media screen and (max-width: $mobileB) {
                  font-size: 4vw;
                  text-align: center;
                }
                font-weight: 200;
                color: #000b77;
                font-size: 1.5vw;
                text-transform: capitalize;
              }
              .filo-left-card {
                @media screen and (max-width: $mobileB) {
                  width: 70vw;
                  height: 40vh;
                  margin-left: 0;
                  flex-direction: column;
                  align-items: center;
                  margin-top: 5vh;
                }
                border: #000b77 1px solid;
                width: 30vw;
                height: 20vh;
                margin-top: 10vh;
                margin-left: 15vw;
                img {
                  @media screen and (max-width: $mobileB) {
                    position: relative;
                    left: 0;
                    width: 60vw;
                  }
                  width: 25vw;
                  position: absolute;
                  left: 5vw;
                }
                .filo-card-exp {
                  @media screen and (max-width: $mobileB) {
                    width: 70vw;
                    margin-left: 0;
                  }
                  width: 15vw;
                  margin-left: 15vw;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  p {
                    @media screen and (max-width: $mobileB) {
                      font-size: 4vw;
                      margin-top: 5vh;
                    }
                    font-size: 1.3vw;
                    font-weight: 200;
                    text-transform: capitalize;
                  }
                  .filo-card-button {
                    p {
                      color: #000;
                      @media screen and (max-width: $mobileB) {
                        font-size: 4vw;
                        margin-top: 0;
                        padding: 0.5vh 8vw;
                      }
                    }
                    margin-top: 5vh;
                    border: #000b77 1px solid;
                    padding: 1vh 3vw;
                  }
                }
              }
            }
            .home-filo-right {
              @media screen and (max-width: $mobileB) {
                width: 100vw;
                margin-left: 0;
                align-items: center;
                margin-top: 10vh;
              }
              width: 45vw;
              flex-direction: column;
              align-items: baseline;
              margin-top: 5vh;
              margin-left: 5vw;
              h5 {
                @media screen and (max-width: $mobileB) {
                  font-size: 4vw;
                  text-align: center;
                }
                font-weight: 200;
                color: #000b77;
                font-size: 1.5vw;
                text-transform: capitalize;
              }
              .filo-right-card {
                @media screen and (max-width: $mobileB) {
                  width: 70vw;
                  height: 40vh;
                  margin-right: 0;
                  flex-direction: column-reverse;
                  align-items: center;
                  margin-top: 5vh;
                }
                border: #000b77 1px solid;
                width: 30vw;
                height: 20vh;
                margin-top: 10vh;
                img {
                  @media screen and (max-width: $mobileB) {
                    position: relative;
                    left: 0;
                    width: 60vw;
                    margin-top: 0;
                  }
                  width: 25vw;
                  position: absolute;
                  left: 70vw;
                  margin-top: -2vh;
                }
                .filo-card-exp {
                  @media screen and (max-width: $mobileB) {
                    width: 70vw;
                    margin-right: 0;
                  }
                  width: 15vw;
                  margin-right: 15vw;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  p {
                    @media screen and (max-width: $mobileB) {
                      font-size: 4vw;
                      margin-top: 5vh;
                    }
                    font-size: 1.3vw;
                    font-weight: 200;
                    text-transform: capitalize;
                  }
                  .filo-card-button {
                    @media screen and (max-width: $mobileB) {
                      margin-bottom: 4vh;
                    }
                    p {
                      color: #000;
                      @media screen and (max-width: $mobileB) {
                        font-size: 4vw;
                        margin-top: 0;
                        padding: 0.5vh 8vw;
                      }
                    }
                    margin-top: 5vh;
                    border: #000b77 1px solid;
                    padding: 1vh 3vw;
                  }
                }
              }
            }
          }
        }
      }
      .home-blog {
        @media screen and (max-width: $mobileB) {
          background-size: cover;
          background-position-x: -70vw;
        }
        height: 105vh;
        background-image: url("./assets/home/img/homepageblog.jpg");
        background-repeat: no-repeat;
        background-size: 105vw 110vh;
        flex-direction: column;
        .home-line-plane {
          width: 100vw;
          height: 5vh;
          align-items: center;
          margin-top: 3vh;
          margin-bottom: 3vh;
          hr {
            @media screen and (max-width: $mobileB) {
              height: 1px;
            }
            width: 96vw;
            height: 2px;
            border: none;
            background-color: #000b77;
            margin: 0;
          }
          img {
            height: 4vh;
          }
          .cloud {
            @media screen and (max-width: $mobileB) {
              width: 50vw;
              left: 25vw;
            }
            position: absolute;
            width: 20vw;
            height: auto;
            left: 40vw;
          }
        }
        .home-blog-main {
          flex-direction: column;
          align-items: center;
          .home-blog-main-header {
            @media screen and (max-width: $mobileB) {
              border: none;
            }
            border: #000b77 2px solid;
            border-top: 0;
            padding: 3vh 4vw;
            margin-top: -5.5vh;
            h1 {
              @media screen and (max-width: $mobileB) {
                font-size: 8vw;
              }
              text-transform: uppercase;
              font-size: 3.5vw;
              letter-spacing: 1vw;
              font-weight: 200;
              color: #000b77;
              margin-left: 1vw;
            }
          }
          .home-blog-bottom {
            flex-direction: column;
            align-items: center;
            .home-blog-exp {
              flex-direction: column;
              align-items: center;
              margin-top: 5vh;
              h3 {
                @media screen and (max-width: $mobileB) {
                  font-size: 5vw;
                }
                font-size: 2vw;
                text-transform: uppercase;
                margin-top: 5vh;
                color: #000b77;
                font-weight: 200;
                letter-spacing: 0.6vw;
              }
              hr {
                margin-top: 5vh;
                width: 95%;
              }
            }
            .home-blog-button {
              @media screen and (max-width: $mobileB) {
                width: 70vw;
              }
              border: #000b77 2px solid;
              justify-content: center;
              width: 35vw;
              margin-top: 10vh;
              padding: 2vh 1.5vw;
              background-color: transparentize($color: #fff, $amount: 0.3);
              h3 {
                @media screen and (max-width: $mobileB) {
                  font-size: 5vw;
                }
                font-size: 1.5vw;
                text-transform: uppercase;
                color: #000b77;
                font-weight: 300;
                letter-spacing: 0.6vw;
              }
            }
          }
        }
      }
    }
  }
  .aboutus {
    @media screen and (max-width: $mobileB) {
      height: 140vh;
      justify-content: start;
    }
    width: 100vw;
    height: 120vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10vh;
    .aboutus-con {
      @media screen and (max-width: $mobileB) {
        height: 135vh;
        width: 90vw;
      }
      height: 110vh;
      flex-direction: column;
      width: 85vw;
      border: #000b77 1px solid;
      align-items: center;
      h1 {
        @media screen and (max-width: $mobileB) {
          margin-bottom: 0;
          font-size: 5vw;
        }
        margin-top: 5vh;
        margin-bottom: 10vh;
        text-align: center;
        font-size: 3vw;
        font-weight: 200;
        color: #000b77;
      }
      .aboutus-exp-con {
        @media screen and (max-width: $mobileB) {
          width: 90vw;
        }
        width: 50vw;
        .left-top {
          @media screen and (max-width: $mobileB) {
            display: none;
          }
          position: absolute;
          width: 10vw;
          height: 10vw;
          border-left: #000b77 1px solid;
          border-top: #000b77 1px solid;
        }
        .right-top {
          @media screen and (max-width: $mobileB) {
            display: none;
          }
          position: absolute;
          margin-left: 40vw;
          width: 10vw;
          height: 10vw;
          border-right: #000b77 1px solid;
          border-top: #000b77 1px solid;
        }
        .right-bot {
          @media screen and (max-width: $mobileB) {
            display: none;
          }
          position: absolute;
          margin-left: 40vw;
          margin-top: 65vh;
          width: 10vw;
          height: 10vw;
          border-right: #000b77 1px solid;
          border-bottom: #000b77 1px solid;
        }
        .left-bot {
          @media screen and (max-width: $mobileB) {
            display: none;
          }
          position: absolute;
          margin-top: 65vh;
          width: 10vw;
          height: 10vw;
          border-bottom: #000b77 1px solid;
          border-left: #000b77 1px solid;
        }
        p {
          @media screen and (max-width: $mobileB) {
            font-size: 4vw;
          }
          padding: 5vh 5vw;
          font-weight: 200;
          line-height: 4vh;
          font-size: 1.1vw;
        }
      }
    }
  }
  .whyus {
    @media screen and (max-width: $mobileB) {
      height: 350vh;
      justify-content: start;
    }
    width: 100vw;
    height: 220vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10vh;
    .whyus-con {
      @media screen and (max-width: $mobileB) {
        height: 340vh;
        justify-content: start;
      }
      height: 210vh;
      flex-direction: column;
      width: 85vw;
      border: #000b77 1px solid;
      align-items: center;
      h1 {
        @media screen and (max-width: $mobileB) {
          font-size: 8vw;
          margin-bottom: 0;
        }
        margin-top: 5vh;
        margin-bottom: 10vh;
        text-align: center;
        font-size: 3vw;
        font-weight: 200;
        color: #000b77;
      }
      .whyus-exp-con {
        @media screen and (max-width: $mobileB) {
          width: 80vw;
        }
        width: 50vw;
        .left-top {
          @media screen and (max-width: $mobileB) {
            display: none;
          }
          position: absolute;
          width: 10vw;
          height: 10vw;
          border-left: #000b77 1px solid;
          border-top: #000b77 1px solid;
        }
        .right-top {
          @media screen and (max-width: $mobileB) {
            display: none;
          }
          position: absolute;
          margin-left: 40vw;
          width: 10vw;
          height: 10vw;
          border-right: #000b77 1px solid;
          border-top: #000b77 1px solid;
        }
        .right-bot {
          @media screen and (max-width: $mobileB) {
            display: none;
          }
          position: absolute;
          margin-left: 40vw;
          margin-top: 160vh;
          width: 10vw;
          height: 10vw;
          border-right: #000b77 1px solid;
          border-bottom: #000b77 1px solid;
        }
        .left-bot {
          @media screen and (max-width: $mobileB) {
            display: none;
          }
          position: absolute;
          margin-top: 160vh;
          width: 10vw;
          height: 10vw;
          border-bottom: #000b77 1px solid;
          border-left: #000b77 1px solid;
        }
        p {
          @media screen and (max-width: $mobileB) {
            font-size: 4vw;
          }
          padding: 5vh 5vw;
          font-weight: 200;
          line-height: 4vh;
          font-size: 1.1vw;
        }
      }
    }
  }
  .vision {
    @media screen and (max-width: $mobileB) {
      height: 90vh;
      justify-content: start;
    }
    width: 100vw;
    height: 110vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10vh;
    .vision-con {
      @media screen and (max-width: $mobileB) {
        height: 80vh;
      }
      height: 90vh;
      flex-direction: column;
      width: 85vw;
      border: #000b77 1px solid;
      align-items: center;
      h1 {
        @media screen and (max-width: $mobileB) {
          margin-bottom: 0;
          font-size: 4vh;
        }
        margin-top: 5vh;
        margin-bottom: 10vh;
        text-align: center;
        font-size: 3vw;
        font-weight: 200;
        color: #000b77;
      }
      .vision-exp-con {
        @media screen and (max-width: $mobileB) {
          width: 80vw;
        }
        width: 50vw;
        .left-top {
          @media screen and (max-width: $mobileB) {
            display: none;
          }
          position: absolute;
          width: 10vw;
          height: 10vw;
          border-left: #000b77 1px solid;
          border-top: #000b77 1px solid;
        }
        .right-top {
          @media screen and (max-width: $mobileB) {
            display: none;
          }
          position: absolute;
          margin-left: 40vw;
          width: 10vw;
          height: 10vw;
          border-right: #000b77 1px solid;
          border-top: #000b77 1px solid;
        }
        .right-bot {
          @media screen and (max-width: $mobileB) {
            display: none;
          }
          position: absolute;
          margin-left: 40vw;
          margin-top: 20vh;
          width: 10vw;
          height: 10vw;
          border-right: #000b77 1px solid;
          border-bottom: #000b77 1px solid;
        }
        .left-bot {
          @media screen and (max-width: $mobileB) {
            display: none;
          }
          position: absolute;
          margin-top: 20vh;
          width: 10vw;
          height: 10vw;
          border-bottom: #000b77 1px solid;
          border-left: #000b77 1px solid;
        }
        p {
          @media screen and (max-width: $mobileB) {
            font-size: 4vw;
          }
          padding: 5vh 5vw;
          font-weight: 200;
          line-height: 4vh;
          font-size: 1.1vw;
        }
      }
    }
  }
  .director {
    @media screen and (max-width: $mobileB) {
      height: 270vh;
      justify-content: start;
    }
    width: 100vw;
    height: 180vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10vh;
    .director-con {
      @media screen and (max-width: $mobileB) {
        height: 260vh;
      }
      height: 170vh;
      flex-direction: column;
      width: 85vw;
      border: #000b77 1px solid;
      align-items: center;
      h1 {
        @media screen and (max-width: $mobileB) {
          margin-bottom: 0;
          font-size: 4vh;
        }
        margin-top: 5vh;
        margin-bottom: 10vh;
        text-align: center;
        font-size: 3vw;
        font-weight: 200;
        color: #000b77;
      }
      .director-exp-con {
        @media screen and (max-width: $mobileB) {
          width: 80vw;
        }
        width: 50vw;
        .left-top {
          @media screen and (max-width: $mobileB) {
            display: none;
          }
          position: absolute;
          width: 10vw;
          height: 10vw;
          border-left: #000b77 1px solid;
          border-top: #000b77 1px solid;
        }
        .right-top {
          @media screen and (max-width: $mobileB) {
            display: none;
          }
          position: absolute;
          margin-left: 40vw;
          width: 10vw;
          height: 10vw;
          border-right: #000b77 1px solid;
          border-top: #000b77 1px solid;
        }
        .right-bot {
          @media screen and (max-width: $mobileB) {
            display: none;
          }
          position: absolute;
          margin-left: 40vw;
          margin-top: 125vh;
          width: 10vw;
          height: 10vw;
          border-right: #000b77 1px solid;
          border-bottom: #000b77 1px solid;
        }
        .left-bot {
          @media screen and (max-width: $mobileB) {
            display: none;
          }
          position: absolute;
          margin-top: 125vh;
          width: 10vw;
          height: 10vw;
          border-bottom: #000b77 1px solid;
          border-left: #000b77 1px solid;
        }
        p {
          @media screen and (max-width: $mobileB) {
            font-size: 4vw;
          }
          padding: 5vh 5vw;
          font-weight: 200;
          line-height: 4vh;
          font-size: 1.1vw;
        }
      }
    }
  }
  .services {
    flex-direction: column;
    margin-top: 10vh;
    .vafa {
      @media screen and (max-width: $mobileB) {
        height: 220vh;
      }
      height: 100vh;
    }
    .han {
      @media screen and (max-width: $mobileB) {
        height: 150vh;
      }
      height: 100vh;
    }
    .aoc {
      @media screen and (max-width: $mobileB) {
        height: 320vh;
      }
      height: 150vh;
    }
    .maintenance {
      @media screen and (max-width: $mobileB) {
        height: 220vh;
      }
      height: 120vh;
    }
    .services-main {
      @media screen and (max-width: $mobileB) {
        flex-direction: column;
      }
      width: 100vw;
      .services-img-con {
        @media screen and (max-width: $mobileB) {
          width: 100vw;
          height: auto;
        }
        width: 40%;
        height: 100%;
        img {
          width: 100%;
        }
      }
      .services-exp {
        @media screen and (max-width: $mobileB) {
          width: 100vw;
        }
        margin-left: 5vw;
        width: 55vw;
        margin-top: 5vh;
        .services-exp-logo {
          @media screen and (max-width: $mobileB) {
            display: none;
          }
          width: 10vw;
          height: 50vh;
          margin-top: 5vh;
          img {
            width: 100%;
            height: fit-content;
          }
        }
        .services-exp-con {
          @media screen and (max-width: $mobileB) {
            width: 86vw;
          }
          flex-direction: column;
          width: 40vw;
          margin-left: 2vw;
          .services-vafa-header {
            align-items: center;
            h1 {
              @media screen and (max-width: $mobileB) {
                font-size: 6vw;
              }
              font-size: 3.5vw;
              font-weight: 200;
              color: #000b77;
              letter-spacing: 1.2vw;
            }
            p {
              @media screen and (max-width: $mobileB) {
                font-size: 3vw;
              }
              font-size: 0.9vw;
              font-weight: 300;
              margin-left: 1vw;
            }
            img {
              @media screen and (max-width: $mobileB) {
                width: 3vw;
              }
              margin-left: 1vw;
              margin-right: 1vw;
              width: 1.5vw;
            }
            a {
              @media screen and (max-width: $mobileB) {
                font-size: 2.5vw;
              }
              text-decoration: none;
              color: #000;
            }
          }
          .services-vafa-exp {
            margin-top: 5vh;
            p {
              @media screen and (max-width: $mobileB) {
                font-size: 4vw;
              }
              font-size: 1vw;
              font-weight: 200;
              line-height: 4vh;
              strong {
                font-weight: 500;
              }
            }
          }
          .services-card {
            flex-direction: column;
            margin-top: 5vh;
            p {
              @media screen and (max-width: $mobileB) {
                font-size: 5vw;
              }
              color: #000b77;
              font-size: 1.2vw;
              font-weight: 200;
            }
            a {
              @media screen and (max-width: $mobileB) {
                font-size: 3.5vw;
              }
              margin-top: 1.5vh;
              color: #000;
              font-size: 1vw;
            }
          }
        }
      }
    }
  }
  .fleet {
    width: 100vw;
    margin-top: 10vh;
    .fleet-main {
      width: 100%;
      flex-direction: column;
      align-items: center;
      margin-top: 5%;
      h1 {
        @media screen and (max-width: $mobileB) {
          font-size: 6vw;
        }
        font-weight: 200;
        font-size: 3.5vw;
        color: #000b77;
        text-transform: capitalize;
        letter-spacing: 0.7vw;
      }
      img {
        @media screen and (max-width: $mobileB) {
          width: 70%;
          margin-bottom: 5vh;
        }
        margin-top: 4vh;
        width: 35%;
      }
      p {
        @media screen and (max-width: $mobileB) {
          font-size: 2vh;
          width: 95%;
        }
        text-align: center;
        margin-top: 5%;
        font-size: 1.5vw;
        font-weight: 200;
        color: #000b77;
        line-height: 5vh;
        margin-bottom: 5vh;
      }
    }
  }
  .blog-card {
    width: 45%;
    flex-direction: column;
    @media screen and (max-width: $mobileB) {
      width: 90%;
      margin-bottom: 10%;
    }
    img {
      width: 80%;
      align-self: center;
    }
    .blog-card-head {
      height: 10%;
      justify-content: space-between;
      margin-top: 5vh;
      img {
        align-self: center;
        width: 25%;
        height: 3px;
        margin: 0;
      }
      h1 {
        width: 70%;
        font-size: 2vw;
        font-weight: 200;
        color: #000b77;
        letter-spacing: 0.5vw;
        text-transform: capitalize;
      }
    }
    .blog-card-cont-date {
      justify-content: space-between;
      margin-top: 5%;
      margin-bottom: 5%;
      margin-left: 1%;
      .cont-date-logo {
        align-items: center;
        a {
          margin-left: 10%;
        }
      }
      .cont-date-con {
        border: #000b77 1px solid;
        padding: 10px 20px;
        p {
          color: #000b77;
          margin: 0;
          font-size: 12px;
          font-weight: 600;
        }
      }
    }
    .blog-card-exp {
      height: 50%;
      margin-left: 2%;
      flex-direction: column;
      p {
        color: #000b77;
        line-height: 22px;
        font-weight: 300;
      }
      a {
        text-decoration: none;
        margin-top: 2.5vh;
        margin-bottom: 2.5vh;
        p {
          display: inline-flex;
          border: #000b77 1px solid;
          padding: 5px 20px;
        }
      }
    }
    .blog-card-foot {
      justify-content: space-between;
      img {
        align-self: center;
        width: 75%;
        height: 2px;
        @media screen and (max-width: $mobileB) {
          width: 60%;
        }
      }
      p {
        display: inline-flex;
        color: #000b77;
        font-size: 12px;
        margin-right: 2vw;
      }
    }
  }
  .blog-home {
    @media screen and (max-width: $mobileB) {
      height: 260vh;
    }
    flex-direction: column;
    height: 220vh;
    width: 100vw;
    .blog-home-main {
      margin-top: 10vh;
      hr {
        @media screen and (max-width: $mobileB) {
          display: none;
        }
        color: #000b77;
      }
      .blog-home-cards {
        @media screen and (max-width: $mobileB) {
          align-items: center;
          flex-direction: column;
        }
      }
    }
  }
  .footer {
    width: 100vw;
    flex-direction: column;
    .home-line-plane {
      width: 100vw;
      height: 5vh;
      align-items: center;
      margin-top: 3vh;
      margin-bottom: 3vh;
      hr {
        @media screen and (max-width: $mobileB) {
          height: 1px;
        }
        width: 96vw;
        height: 2px;
        border: none;
        background-color: #000b77;
        margin: 0;
      }
      img {
        height: 4vh;
      }
      .cloud {
        @media screen and (max-width: $mobileB) {
          width: 50vw;
          left: 25vw;
        }
        position: absolute;
        width: 20vw;
        height: auto;
        left: 40vw;
      }
    }
    .footer-main {
      flex-direction: column;
      .footer-header {
        justify-content: center;
        h1 {
          @media screen and (max-width: $mobileB) {
            font-size: 7vw;
          }
          text-transform: uppercase;
          font-size: 3vw;
          letter-spacing: 1vw;
          font-weight: 200;
          color: #000b77;
          margin-left: 2vw;
        }
      }
      .footer-contact {
        flex-direction: column;
        align-items: start;
        margin-top: 5vh;
        h2 {
          @media screen and (max-width: $mobileB) {
            font-size: 7vw;
            margin-bottom: 0.5vh;
          }
          font-weight: 200;
          font-size: 1.3vw;
          letter-spacing: 0.3vw;
          margin-left: 5%;
          color: #000b77;
        }
        hr {
          @media screen and (max-width: $mobileB) {
            width: 43vw;
          }
          margin: 0;
          width: 15%;
          color: #000b77;
          border: #000b77 1px solid;
        }
      }
      .footer-adress {
        @media screen and (max-width: $mobileB) {
          flex-direction: column;
        }
        margin-top: 10vh;
        justify-content: center;
        column-gap: 2vw;
        .footer-adress-con {
          @media screen and (max-width: $mobileB) {
            width: 90vw;
            margin: auto;
          }
          flex-direction: column;
          width: 20vw;
          h3 {
            @media screen and (max-width: $mobileB) {
              font-size: 4vw;
            }
            font-size: 1.8vw;
            font-weight: 200;
            color: #000b77;
            text-align: center;
            letter-spacing: 0.2vw;
          }
          p {
            @media screen and (max-width: $mobileB) {
              font-size: 4vw;
              text-align: center;
            }
            margin-top: 2.5vh;
            font-weight: 200;
            font-size: 1.1vw;
            color: #000b77;
            line-height: 3.2vh;
          }
        }
      }
      .footer-info {
        @media screen and (max-width: $mobileB) {
          margin-top: 5vh;
        }
        margin-top: 15vh;
        flex-direction: column;
        align-items: center;
        div {
          margin-bottom: 5vh;
        }
        a {
          @media screen and (max-width: $mobileB) {
            font-size: 3vw;
          }
          text-decoration: none;
          color: #000b77;
          margin-right: 5vw;
          font-size: 1.2vw;
          font-weight: 200;
        }
      }
      .footer-social {
        @media screen and (max-width: $mobileB) {
          margin-top: 5vh;
        }
        justify-content: center;
        margin-top: 10vh;
        img {
          @media screen and (max-width: $mobileB) {
            width: 10vw;
          }
          width: 4vw;
          margin-right: 2.5vw;
        }
      }
      .footer-rights {
        margin-top: 5vh;
        margin-bottom: 5vh;
        justify-content: center;
        h5 {
          @media screen and (max-width: $mobileB) {
            font-size: 3vw;
          }
          color: #000b77;
          font-weight: 200;
          font-size: 1.2vw;
        }
      }
    }
  }
  .blog {
    flex-direction: column;
    .blog-main {
      width: 100vw;
      margin-top: 10vh;
      flex-direction: column;
      img {
        @media screen and (max-width: $mobileB) {
          width: 100vw;
        }
        justify-self: center;
        align-self: center;
        width: 80vw;
      }
      p {
        @media screen and (max-width: $mobileB) {
          font-size: 4vw;
        }
        margin: auto;
        margin-top: 10vh;
        width: 80vw;
        font-size: 1.2vw;
        font-weight: 300;
        line-height: 4vh;
      }
      strong {
        font-weight: 400;
      }
      a {
        text-decoration: none;
        color: #000b77;
      }
    }
    
  }

  .graduates {
    display: flex;
    flex-direction: column;
   
    .graduates-main-con {
      display: flex;
      flex-direction: column;
      .graduates-head {
        display: flex;
        text-align: center;
        margin: auto;
        color: #000b77;
        margin-top: 10%;

        h1 {
          font-size: 32px;
          font-weight: 400;
          letter-spacing: 10px;
          @media screen and (max-width: $mobileB) {
            width: 90%;
            margin: auto;
            margin-bottom: 10%;
            margin-top: 10%;
            font-size: 5vw;
            letter-spacing: 3vw;
          }
        }
      }
      .graduates-body {
        justify-content: space-between;
        margin-bottom: 10%;
        @media screen and (max-width: $mobileB) {
          flex-direction: column;
        }
        .graduate-left {
          display: flex;
          flex-direction: column;
          width: 40%;
          @media screen and (max-width: $mobileB) {
            width: 100%;
          }
        }
        .graduate-mid {
          @media screen and (max-width: $mobileB) {
            display: none;
          }
          display: flex;
          flex-direction: column;
          width: 10%;
          margin-top: 4%;
          img {
            width: 3px;
            height: 100%;
            margin: auto;
          }
        }
        .graduate-right {
          display: flex;
          flex-direction: column;
          width: 40%;
          @media screen and (max-width: $mobileB) {
            width: 100%;
          }
        }
      }
    }
  }
  .graduates-card {
    display: flex;
    flex-direction: column;
    margin-top: 20%;
    img {
      width: 100%;
    }
    .graduates-card-info {
      width: 90%;
      margin: auto;
      align-items: center;
      margin-top: 2%;
      h5 {
        font-size: 16px;
        color: #000b77;
        margin: auto;
        width: 25%;
        @media screen and (max-width: $mobileB) {
          font-size: 3.5vw;
        }
      }
      img {
        margin-left: 2%;
        margin-right: 2%;
        height: 3px;
        width: 5%;
      }
    }
    h1 {
      text-align: center;
      color: #000b77;
      font-weight: 300;
    }
    p {
      color: #000b77;
      text-align: center;
      font-weight: 100;
      line-height: 24px;
      margin-left: 2%;
      margin-right: 2%;
    }
  }

  .whatsapp-button {
    
    position: fixed;
    bottom: 20px; /* İstediğiniz boşluk miktarını ayarlayın */
    right: 20px; /* İstediğiniz boşluk miktarını ayarlayın */
    z-index: 9999; /* Diğer öğelerin üzerinde görüntülenmesini sağlamak için yüksek bir z-index değeri belirleyin */
    img{
      width: 80px;
    }
  }

  .popup {
    z-index: 99;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Siyah yarı şeffaf arka plan */
    z-index: 1;
  }
  
  .popup-content {
    position: relative;
    z-index: 2; /* Overlay'den üstte olmalı */
    background-color: #fff;
    padding: 35px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    max-width: 600px;
    max-height: 600px;
    overflow: auto; /* Gerektiğinde kaydırma çubuklarını görüntüle */
    @media screen and (max-width: 600px) {
      position: relative;
    z-index: 2; /* Overlay'den üstte olmalı */
    background-color: #fff;
    padding: 35px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    max-width: 300px;
    max-height: 300px;
    overflow: auto; /* Gerektiğinde kaydırma çubuklarını görüntüle */
    
    }
  }
  
  .popup-image {
    max-width: 100%;
    max-height: 100%;
    display: block; /* Resim etrafındaki beyaz alanı kaldırmak için */
    margin: 0 auto; /* Resmi merkezlemek için */
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
    background: none;
    border: none;
    color: #333; /* Kapat butonunun rengini ayarlayabilirsiniz */
  }



  /* Pop-up genel stil */
.custom-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); /* Arka plan için siyah şeffaf overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

/* Pop-up içerik */
.custom-popup-content {
  position: relative;
  width: 600px;
  height: 600px; /* Kare boyut: 600x600 */
  background-color: #fff;
  border-radius: 8px; /* Köşeleri yuvarlatmak için */
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

/* Kapama butonu */
.custom-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #17217c;
  border: none;
  color: #fff;
  font-size: 16px;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.custom-close-button:hover {
  background: #000b77;
}

/* Overlay tıklama alanı */
.custom-popup-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Iframe stil */
.custom-popup-iframe {
  width: 100%;
  height: 100%;
  border: none;
}




  
}
